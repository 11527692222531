export const ApiRoutes = {
  // API_HOSTNAME: "http://192.168.29.62:4081",
  // WS_HOSTNAME: "ws://192.168.29.62:4081/",

  API_HOSTNAME: "https://connect.aamantranam.com",
  WS_HOSTNAME: "wss://connect.aamantranam.com:4000/",

  LOGIN: "login",
  REGISTRATION: "registration",
  CHECKUSERACCOUNT: "checkuseraccount",
  CHECKEMAIL: "checkemail",
  REGISTEROTP: "register-otp",
  VERIFYREGISTRATIONOTP: "check-register-otp",
  FORGOTPASS: "forgot-password",
  RESETPASSWORD: "reset-password",
  CHECKDEVICE: "check-device",
  CREATECAMPAIGN: "create-campaign",
  GENERATEAPIKEY: "generateapikey",
  CHANGEPASSWORD: "changepassword",
  CAMPAIGNLIST: "get-campaignlist",
  CAMPAIGNREPORT: "get-campaignreportbyid",

  GETALLPACKAGELIST: "get-allpackagelist",
  REMAINGCREDIT: "remainig-credit",
  REQUESTTOADDBALANCE: "requesttoaddbalance",
  PACKAGEPURCHASEHISTORY: "packagepurchase-history",
  GETUSERDEVICE: "get-userdevice",
  DEVICELOGOUT: "device-logout",
  APPROVALCREDITREQUEST: "approval-creditrequest",
  UPDATECREDITREQUEST: "update-creditrequest",
  GETPACKAGEPURCHASEREQLIST: "get-packagepurchase-reqlist",
  GETMESSAGELIST: "get-messagelist",
  ADMINDASHBOARDCOUNTER: "admin-dashboardcounter",
  USERDASHBOARDCOUNTER: "user-dashboardcounter",

  NEWREGISTERUSERLIST: "new-registeruserlist",
  LOWCREDITUUSERLIST: "low-credituserlist",
  INSERTSLIDER: "insert-slider",
  SLIDERLIST: "get-sliderlist",
  DELETESLIDER: "delete-slider",

  CREATEPACKAGE: "create-package",

  GETBLANCE: "get-balance",
  UPDATEUSERSTATUS: "update-userstatus",

  GETSETTINGDATA: "get-settingdata",
  UPDATESETTING: "update-setting",

  GETALLUSERLIST: "get-alluserlist",

  GETADMINDEVICELIST: "get-admindevicelist",
  CHANGEPACKAGESTATUS: "change-packagestatus",
  GETALLPACKAGELISTADMIN: "get-allpackagelistadmin",
  UPDATEPACKAGE: "update-package",
  UPDATEUSERPROFILE: "update-userprofile",
  CHECKOTP: "check-otp",
  GETUSERDETAIL: "get-userdetailbyid",

  SENDMESSAGE: "send-event-message",
  SENDMLASTESSAGE: "send-last-message",
  EVENTSLIST: "get-eventlist",
  EVENTDATABYID: "get-eventdatabyid",
  EVENTREPORTBYID: "get-eventreportbyid",
  EVENTALLREPORTBYID: "get-eventallreportbyid",
  RESENDMESSAGE: "resend-message",
  PAUSECAMPAIGN: "pause-campaign",

  ADDCAMPAIGN: "add-campaign",
  GETALLCAMPAIGN: "get-campaignlistbyconnectionid",
  CAMPAIGNREPORTBYID: "get-campaignreportbyid",
};
