// ** Logo
import logo from "@src/assets/images/logo/logo.png";
import themeConfig from "@configs/themeConfig";

import { ApiRoutes } from "../../constants";

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner app-loader ">
      <img
        className="fallback-logo"
        style={{ maxWidth: "200px" }}
        src={ApiRoutes.API_HOSTNAME + "/logo/logo.png"}
        alt="logo"
      />
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
    </div>
  );
};

export default SpinnerComponent;
