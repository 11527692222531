import mock from './mock'

import './jwt'
import './select'


import './apps/userList'

import './apps/eCommerce'



import './navbar/navbarSearch'
import './pages/knowledge-base'

import './cards/card-analytics'
import './cards/card-statistics'
import './autoComplete/autoComplete'

mock.onAny().passThrough()
