import mock from '../mock'

export const searchArr = [
  {
    groupTitle: 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'analyticsDash',
        isBookmarked: false,
        title: 'Analytics Dashboard',
        icon: 'Home',
        link: '/dashboard/analytics'
      },
      {
        id: 2,
        target: 'eCommerceDash',
        isBookmarked: false,
        title: 'eCommerce Dashboard',
        icon: 'Home',
        link: '/'
      },
      
     
     
     
  
    
      {
        id: 15,
        target: 'userList',
        isBookmarked: false,
        title: 'User List',
        icon: 'User',
        link: '/apps/user/list'
      },
      {
        id: 16,
        target: 'userView',
        isBookmarked: false,
        title: 'User View',
        icon: 'User',
        link: '/apps/user/view'
      },
    
      {
        id: 19,
        target: 'login-cover',
        isBookmarked: false,
        title: 'Login Cover',
        icon: 'LogIn',
        link: '/pages/login-cover'
      },
  
      {
        id: 21,
        target: 'register-cover',
        isBookmarked: false,
        title: 'Register Cover',
        icon: 'UserPlus',
        link: '/pages/register-cover'
      },
    
      {
        id: 23,
        target: 'forgotPassword-cover',
        isBookmarked: false,
        title: 'Forgot Password Cover',
        icon: 'Crosshair',
        link: '/pages/forgot-password-cover'
      },
   
      {
        id: 25,
        target: 'resetPassword-cover',
        isBookmarked: false,
        title: 'Reset Password Cover',
        icon: 'TrendingUp',
        link: '/pages/reset-password-cover'
      },
     
    
      
     
     

      {
        id: 34,
        target: 'comingSoon',
        isBookmarked: false,
        title: 'Coming Soon Page',
        icon: 'Watch',
        link: '/misc/coming-soon'
      },

      {
        id: 35,
        target: 'notAuthorized',
        isBookmarked: false,
        title: 'Not Authorized Page',
        icon: 'UserX',
        link: '/misc/not-authorized'
      },
      {
        id: 36,
        target: 'maintenance',
        isBookmarked: false,
        title: 'Maintenance Page',
        icon: 'Aperture',
        link: '/misc/maintenance'
      },
      {
        id: 37,
        target: 'error',
        isBookmarked: false,
        title: 'Error',
        icon: 'AlertTriangle',
        link: '/misc/error'
      },
     
   
    

      {
        id: 82,
        target: 'i18n',
        isBookmarked: false,
        title: 'I18n',
        icon: 'Globe',
        link: '/extensions/i18n'
      },
     
      {
        id: 84,
        target: 'extPagination',
        isBookmarked: false,
        title: 'React Paginate',
        icon: 'MoreHorizontal',
        link: '/extensions/pagination'
      },
      {
        id: 85,
        target: 'extImport',
        isBookmarked: false,
        title: 'Import',
        icon: 'DownloadCloud',
        link: '/extensions/import'
      },
      {
        id: 86,
        target: 'extExport',
        isBookmarked: false,
        title: 'Export',
        icon: 'UploadCloud',
        link: '/extensions/export'
      },
      {
        id: 87,
        target: 'extExportSelected',
        isBookmarked: false,
        title: 'Export Selected',
        icon: 'CheckSquare',
        link: '/extensions/export-selected'
      },
      {
        id: 88,
        target: 'pageLayout-collapsedMenu',
        isBookmarked: false,
        title: 'Collapsed Menu Page Layout',
        icon: 'Layout',
        link: '/page-layout/collapse-menu'
      },
      {
        id: 89,
        target: 'pageLayout-layoutBoxed',
        isBookmarked: false,
        title: 'Boxed Page Layout',
        icon: 'Layout',
        link: '/page-layout/layout-boxed'
      },
      {
        id: 90,
        target: 'pageLayout-withoutMenu',
        isBookmarked: false,
        title: 'Without Menu Page Layout',
        icon: 'Layout',
        link: '/page-layout/without-menu'
      },
      {
        id: 91,
        target: 'pageLayout-layoutEmpty',
        isBookmarked: false,
        title: 'Empty Page Layout',
        icon: 'Layout',
        link: '/page-layout/layout-empty'
      },
      {
        id: 92,
        target: 'pageLayout-layoutBlank',
        isBookmarked: false,
        title: 'Blank Page Layout',
        icon: 'Layout',
        link: '/page-layout/layout-blank'
      },
     
     
 
        
   
     
    
  
     
   
    
      {
        id: 125,
        icon: 'AtSign',
        isBookmarked: false,
        target: 'verifyEmailBasic',
        title: 'Verify Email Basic',
        link: '/pages/verify-email-basic'
      },
      {
        id: 126,
        icon: 'AtSign',
        isBookmarked: false,
        target: 'verifyEmailCover',
        title: 'Verify Email Cover',
        link: '/pages/verify-email-cover'
      },
      {
        id: 127,
        icon: 'HelpCircle',
        isBookmarked: false,
        target: 'TwoStepBasic',
        link: '/pages/two-steps-basic',
        title: 'Two Step Verification Basic'
      },
      {
        id: 128,
        icon: 'HelpCircle',
        isBookmarked: false,
        target: 'TwoStepCover',
        link: '/pages/two-steps-cover',
        title: 'Two Step Verification Cover'
      },
      {
        id: 129,
        icon: 'CheckSquare',
        isBookmarked: false,
        target: 'appKanban',
        link: '/apps/kanban',
        title: 'Kanban'
      }
    ]
  },
  {
    groupTitle: 'Files',
    searchLimit: 4,
    data: [
      {
        title: 'Passport Image',
        by: 'Oliver Queen',
        size: '52kb',
        file: require('@src/assets/images/icons/jpg.png').default
      },
      {
        title: 'Parenting Guide',
        by: 'Alfred Pennyworth',
        size: '2.3mb',
        file: require('@src/assets/images/icons/doc.png').default
      },
      {
        title: 'Class Notes',
        by: 'Barry Allen',
        size: '30kb',
        file: require('@src/assets/images/icons/doc.png').default
      },
      {
        title: 'Class Attendance',
        by: 'Walter White',
        size: '52mb',
        file: require('@src/assets/images/icons/xls.png').default
      }
    ]
  },
  {
    groupTitle: 'Contacts',
    searchLimit: 4,
    data: [
      {
        title: 'Mia Davis',
        email: 'miadavis@teleworm.us',
        img: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
        date: '01/03/2020'
      },
      {
        title: 'Norris Carrière',
        email: 'NorrisCarriere@rhyta.com',
        img: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
        date: '07/03/2020'
      },
      {
        title: 'Charlotte Gordon',
        email: 'CharlotteGordon@jourrapide.com',
        img: require('@src/assets/images/portrait/small/avatar-s-26.jpg').default,
        date: '14/03/2020'
      },
      {
        title: 'Robert Nash',
        email: 'RobertNash@dayrep.com',
        img: require('@src/assets/images/portrait/small/avatar-s-25.jpg').default,
        date: '21/03/2020'
      }
    ]
  }
]

// GET Search Data
mock.onGet('/api/main-search/data').reply(() => {
  return [200, { searchArr }]
})

// GET Search Data & Bookmarks
mock.onGet('/api/bookmarks/data').reply(() => {
  const bookmarks = searchArr[0].data.filter(item => item.isBookmarked)
  const suggestions = searchArr[0].data
  return [200, { suggestions, bookmarks }]
})

// POST Update isBookmarked
mock.onPost('/api/bookmarks/update').reply(config => {
  const { id } = JSON.parse(config.data)

  const obj = searchArr[0].data.find(item => item.id === id)

  Object.assign(obj, { isBookmarked: !obj.isBookmarked })

  return [200]
})
